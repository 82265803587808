
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

interface IUserActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "claimsActivityView",
  mixins: [ActivitiesMixin],

  data(): IUserActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
          ADD_CLAIMS_REQUEST_SUCCESS,
          ADD_CLAIMS_REQUEST_FAILURE,
          UPDATE_CLAIMS_REQUEST_SUCCESS,
          UPDATE_CLAIMS_REQUEST_FAILURE,
          DELETE_CLAIMS_REQUEST_SUCCESS,
          DELETE_CLAIMS_REQUEST_FAILURE
          `.replace(/\s/g, ""),
        activityType: "Activity"
      };
    }
  }
});
